import { defineStore } from 'pinia'
import type { ThemeMode } from '../types/global'
import type { UseLogin } from '../types/stores'
import type { ViewMode } from '../types/listados';

export const useGlobalStore = defineStore(
  'global',
  ():ThemeMode => {
    const themeMode = ref<string>('light');
    const viewMode = ref<ViewMode>('tipo2');


    return { themeMode, viewMode };
  }, { persist: true },
)

export const useLogin = defineStore(
  'token',
  (): UseLogin => {
    const token = ref<string|null>(null);
    const user_img = ref<string|null>(null);
    const nombre = ref<string|null>(null);
    const apellido = ref<string|null>(null);
    const email = ref<string|null>(null);

    // const rol = ref('');
    return { token, user_img, nombre, apellido, email };
  }, { 
    persist:{
      storage: persistedState.cookiesWithOptions({
        // maxAge: 3,
        sameSite: 'strict',
        // httpOnly: true,
        // secure: true, //obliga a enviar esta cookie por https
        watch: true,
      }),
    }, 
  })
  type DataEmpresa = {
    whatsappLink?: string|null;
    whatsapp?: string|null;
  }
  export const useDataEmpresa = defineStore(
    'dataEmpresa',
    (): DataEmpresa => {
      const whatsappLink = ref<string|null>(null);
      const whatsapp = ref<string|null>(null);

      // const rol = ref('');
      return { whatsappLink, whatsapp };
    }, { 
      persist:{
        storage: persistedState.cookiesWithOptions({
          // maxAge: 3,
          sameSite: 'strict',
          // httpOnly: true,
          // secure: true, //obliga a enviar esta cookie por https
          watch: true,
        }),
      }, 
    })
  
  